<template>
  <div class="pd-20">
    <a-page-header class="pd-0" sub-title="">
      <div slot="title">
        <h1>Relatório Contas a Receber</h1>
      </div>
    </a-page-header>

    <a-row class="haya-panels mb-20" :gutter="20">
      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contract.pagination.complete_list_total }}
          </div>
          <div class="txt">Pagamentos</div>
          <div class="footer">
            Total:
            {{ formatPricePtBr(contractInlineReport.total) }}
          </div>
        </div>
      </a-col>

      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contractInlineReport.billingTickets.quantity }}
          </div>
          <div class="txt">Boletos</div>
          <div class="footer">
            Total:
            {{ formatPricePtBr(contractInlineReport.billingTickets.total) }}
          </div>
        </div>
      </a-col>

      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contractInlineReport.paymentCard.quantity }}
          </div>
          <div class="txt">Carta de Crédito</div>
          <div class="footer">
            Total:
            {{ formatPricePtBr(contractInlineReport.paymentCard.total) }}
          </div>
        </div>
      </a-col>

      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contractInlineReport.bankTransfer.quantity }}
          </div>
          <div class="txt">Transferência Bancária</div>
          <div class="footer">
            Total:
            {{ formatPricePtBr(contractInlineReport.bankTransfer.total) }}
          </div>
        </div>
      </a-col>

      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contractInlineReport.customPayment.quantity }}
          </div>
          <div class="txt">Personalizado</div>
          <div class="footer">
            Total:
            {{ formatPricePtBr(contractInlineReport.customPayment.total) }}
          </div>
        </div>
      </a-col>

      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contractInlineReport.creditCard.PDQs.quantity }}
          </div>
          <div class="txt">PDQs</div>
          <div class="footer">
            Total:
            {{ formatPricePtBr(contractInlineReport.creditCard.PDQs.total) }}
          </div>
        </div>
      </a-col>

      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{
              contractInlineReport.creditCard.payDirectWithTheSupplier.quantity
            }}
          </div>
          <div class="txt">Direto Fornecedor</div>
          <div class="footer">
            Total:
            {{
              formatPricePtBr(
                contractInlineReport.creditCard.payDirectWithTheSupplier.total
              )
            }}
          </div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-0 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="fiter-collumns" :gutter="20">
          <a-col :span="3">
            <div class="travel-input">
              <label for class="filled">ID Venda</label>
              <a-input
                placeholder="ID + Enter"
                v-model="contract.filters.saleId"
                @pressEnter="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="3">
            <div class="travel-input">
              <label for class="filled">ID do contrato</label>
              <a-input
                placeholder="ID + Enter"
                v-model="contract.filters.id"
                @pressEnter="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Contratante </label>
              <a-auto-complete
                :data-source="
                  customers.list.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="customers.filters.searchTerm"
                style="width: 100%; height: 32px"
                placeholder="Buscar contratantes..."
                @change="
                  customers.filters.searchTerm ? getCustomers() : getContracts()
                "
                @select="customerSelected"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Empresas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="contract.filters.companyBranches.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of companyBranches.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }} ({{ item.company.trading_name }})
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Período</label>

              <a-range-picker
                v-model="contract.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="12">
            <div class="field payments">
              <font class="label">Métodos de Pagamento</font>
              <a-checkbox-group
                style="width: 100%"
                v-model="contract.filters.paymentMethods.selected"
                :disabled="contract.loading ? true : false"
                @change="getContracts()"
              >
                <a-checkbox
                  v-for="(item, index) of paymentMethodsOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Status venda</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="contract.filters.saleStatus.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of contract.filters.saleStatus.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Valor Recebimento (R$)</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="filterValue.min"
                  @change="getContracts()"
                />
                <a-input
                  style="
                    width: 10%;
                    border-left: 0;
                    pointer-events: none;
                    backgroundcolor: #fff;
                    border: 0;
                  "
                  placeholder="até"
                />
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="filterValue.max"
                  @change="getContracts()"
                />
              </a-input-group>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col
            v-if="
              contract.filters.paymentMethods.selected.includes('credit-card')
            "
            :span="8"
          >
            <div class="travel-input">
              <label for class="filled">PDQ</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="filterPdq"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of cardMachines.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col
            v-if="
              contract.filters.paymentMethods.selected.includes(
                'billing-ticket'
              )
            "
            :span="8"
          >
            <div class="travel-input">
              <label for class="filled">Boleto</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="filterBillingTicket"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of billingTickets.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col
            v-if="
              contract.filters.paymentMethods.selected.includes('bank-transfer')
            "
            :span="8"
          >
            <div class="travel-input">
              <label for class="filled">Conta Bancária</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="filterBankTransfer"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of banksList"
                  :key="index"
                  :value="item.account"
                >
                  {{ item.company_name }} ({{ item.account }})
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="filterContractList(contract.complete_list)"
      :loading="contract.loading"
      :pagination="{ pageSize: 30, showSizeChanger: true }"
      :scroll="{ x: 1300 }"
    >
      <div slot="sale_id" slot-scope="record">{{ record.raw.sale_id }}</div>
      <div slot="customer" slot-scope="record">
        <div class="dotted-phrase">
          {{ record.customer.first_name }} {{ record.customer.last_name }}
        </div>
      </div>

      <div slot="payment_receipt_date" slot-scope="record">
        {{ formatMultiDates(record.payment_receipt_date) }}
        {{ record.payment_receipt_date_is_selected ? "" : "*" }}
      </div>

      <div slot="company_details" slot-scope="record">
        {{ record.company_details }}
      </div>

      <div slot="payment" slot-scope="record">
        {{ paymentName(record.payment, record) }}
      </div>

      <div slot="payment_value" slot-scope="record">
        {{
          record.payment_value != undefined
            ? formatPricePtBr(record.payment_value)
            : "---"
        }}
      </div>

      <div slot="card_pdq_tax" slot-scope="record">
        {{
          record.card_pdq_tax != "---"
            ? formatPricePtBr(record.card_pdq_tax)
            : "---"
        }}
      </div>

      <div slot="card_advance_fee" slot-scope="record">
        {{
          record.card_pdq_tax != "---"
            ? formatPricePtBr(record.card_advance_fee)
            : "---"
        }}
      </div>

      <div slot="company_branch" slot-scope="record">
        {{ record.company_branch.name }}
      </div>

      <div slot="payment_status" slot-scope="record">
        {{
          record.payment_status == "Realizado"
            ? "Finalizado"
            : record.payment_status
        }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Ver a venda">
            <a class="edit" @click="viewSale(record.raw.sale_id)">
              <img
                v-if="
                  record.payment_status == 'Pendente' ||
                  record.payment_status == 'Cancelado'
                "
                src="@/assets/images/dashboard/sales/pending-sale.png"
                alt="img"
              />
              <img
                v-else
                src="@/assets/images/dashboard/sales/concluded-sale.png"
                alt="img"
              />
            </a>
          </a-tooltip>
        </div>
      </div>
    </a-table>

    <a-modal
      class="sale-outer-modal"
      :title="false"
      :footer="false"
      :width="$root.windowWidth > 960 ? '1160px' : '100%'"
      v-model="openSaleModal"
      @cancel="onCloseSaleModal"
    >
      <saleModal
        v-if="openSaleModal"
        :id="saleId"
        @closeModal="onCloseSaleModal"
      />
    </a-modal>
  </div>
</template>

<script>
import { format } from "date-fns";
import SaleModal from "@/components/sales/modal/SaleModal.vue";
import formatThings from "@/mixins/general/formatThings";
import customerMixins from "@/mixins/customers/customerMixins";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import billingTicketsMixins from "@/mixins/billing-tickets/billingTicketsMixins.js";

export default {
  mixins: [
    contractMixins,
    customerMixins,
    companyBranchMixins,
    cardMachinesMixins,
    billingTicketsMixins,
    formatThings,
  ],
  components: { SaleModal },
  data() {
    return {
      openSaleModal: false,
      contractInlineReport: {
        total: 0,
        billingTickets: {
          total: 0,
          quantity: 0,
        },
        paymentCard: {
          total: 0,
          quantity: 0,
        },
        creditCard: {
          total: 0,
          quantity: 0,
          PDQs: {
            total: 0,
            quantity: 0,
          },
          payDirectWithTheSupplier: {
            total: 0,
            quantity: 0,
          },
        },
        bankTransfer: {
          total: 0,
          quantity: 0,
        },
        customPayment: {
          total: 0,
          quantity: 0,
        },
      },
      filterPdq: [],
      filterBillingTicket: [],
      filterBankTransfer: [],
      filterValue: {
        min: "",
        max: "",
      },
      paymentMethodsOptions: [
        {
          label: "Boleto",
          value: "billing-ticket",
          show: false,
        },
        {
          label: "Carta Crédito",
          value: "payment-card",
          show: false,
        },
        {
          label: "Cartão Crédito",
          value: "credit-card",
          show: false,
        },
        {
          label: "Link Pag.",
          value: "payment-link",
          show: this.$root.isAdmin() ? false : true,
        },
        {
          label: "Personalizado",
          value: "custom-payment",
          show: false,
        },
        {
          label: "Transf. Banc.",
          value: "bank-transfer",
          show: false,
        },
      ],
      columns: [
        {
          title: "Venda",
          key: "sale_id",
          class: "id",
          width: 90,
          sorter: (a, b) => {
            return a.raw.sale_id - b.raw.sale_id;
          },
          scopedSlots: { customRender: "sale_id" },
        },
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          class: "contract_id",
          sorter: (a, b) => a.id - b.id,
          width: 80,
        },
        {
          title: "Data Recebimento",
          scopedSlots: { customRender: "payment_receipt_date" },
          key: "payment_receipt_date",
          width: 140,
        },

        {
          title: "Contratante",
          scopedSlots: { customRender: "customer" },
          width: 150,
        },
        {
          title: "Valor Recebimento",
          scopedSlots: { customRender: "payment_value" },
          key: "payment_value",
          sorter: (a, b) => {
            return (
              a.payment_value.replace(",", ".") -
              b.payment_value.replace(",", ".")
            );
          },
          width: 150,
        },
        {
          title: "Taxa PDQ",
          scopedSlots: { customRender: "card_pdq_tax" },
          key: "card_pdq_tax",
          sorter: (a, b) => {
            let theFirst =
              a != "---" && a.card_pdq_tax != undefined
                ? a.card_pdq_tax.replace(",", ".")
                : 0.0;
            let theSecond =
              b != "---" && b.card_pdq_tax != undefined
                ? b.card_pdq_tax.replace(",", ".")
                : 0.0;

            return theFirst - theSecond;
          },
          width: 100,
        },
        {
          title: "Taxa ",
          scopedSlots: { customRender: "card_advance_fee" },
          key: "card_advance_fee",
          width: 100,
        },

        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          width: 120,
          sorter: (a, b) =>
            a.company_branch.name.length - b.company_branch.name.length,
        },
        {
          title: "Forma de Recebimento",
          scopedSlots: { customRender: "payment" },
          key: "payment",
          width: 250,
        },
        {
          title: "Status",
          key: "payment_status",
          scopedSlots: { customRender: "payment_status" },
          width: 170,
          sorter: (a, b) => a.payment_status.length - b.payment_status.length,
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 40,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      banksList: [],
    };
  },
  created() {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.contract.filters.status.selected = "concluded";
    this.contract.pagination.perPage = 30;
    this.contract.listVersion = "list-2";
    this.contract.filters.joinSales = "yes";
    this.contract.filters.orderBy = "created";
    this.contract.filters.order = "desc";
    this.contract.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    this.contractUrlDefaults();
    this.getContracts();
  },
  mounted() {
    this.getCompanyBranches();

    this.cardMachines.filters.status.selected = "Ativo";
    this.getCardMachines();

    this.getBillingTickets();

    this.$http
      .get(`/company-bank-accounts/list?page=1&per_page=200`)
      .then(({ data }) => {
        this.banksList = data.data;
      });
  },
  methods: {
    filterContractList(contractList) {
      let filteredContracts = [];

      if (contractList !== undefined) {
        if (
          this.contract.filters.paymentMethods.selected.length > 0 ||
          this.contract.filters.saleStatus.selected.length > 0 ||
          this.filterPdq.length > 0 ||
          this.filterValue.min ||
          this.filterValue.max
        ) {
          contractList.forEach((contract) => {
            if (
              this.filterPaymentMethods(contract) &&
              this.filterPaymentStatus(contract) &&
              this.filterPDQs(contract) &&
              this.filterBillingTickets(contract) &&
              this.filterBankTransfers(contract) &&
              this.filterValues(contract)
            ) {
              filteredContracts.push(contract);
            }
          });
        } else {
          filteredContracts = contractList;
        }

        this.contract.pagination.complete_list_total = filteredContracts.length;

        let theTotal = 0,
          billingTicket = {
            total: 0,
            quantity: 0,
          },
          paymentCard = {
            total: 0,
            quantity: 0,
          },
          creditCard = {
            total: 0,
            quantity: 0,
            PDQs: {
              total: 0,
              quantity: 0,
            },
            payDirectWithTheSupplier: {
              total: 0,
              quantity: 0,
            },
          },
          bankTransfer = {
            total: 0,
            quantity: 0,
          },
          customPayment = {
            total: 0,
            quantity: 0,
          };

        filteredContracts.forEach((contract) => {
          let theValue = contract.payment_value
            ? parseFloat(contract.payment_value.replace(",", "."))
            : 0.0;

          theTotal += theValue;

          if (contract.payment == "billing-ticket") {
            billingTicket.total += theValue;
            billingTicket.quantity += 1;
          }

          if (contract.payment == "payment-card") {
            paymentCard.total += theValue;
            paymentCard.quantity += 1;
          }

          if (contract.payment == "credit-card") {
            creditCard.total += theValue;
            creditCard.quantity += 1;

            let cardMachineId =
              contract.meta[
                `payment_methods_credit_card_machine_id_${contract.payment_card_id}`
              ];

            if (cardMachineId == 14) {
              creditCard.payDirectWithTheSupplier.total += theValue;
              creditCard.payDirectWithTheSupplier.quantity += 1;
            } else {
              creditCard.PDQs.total += theValue;
              creditCard.PDQs.quantity += 1;
            }
          }

          if (contract.payment == "bank-transfer") {
            bankTransfer.total += theValue;
            bankTransfer.quantity += 1;
          }

          if (contract.payment == "custom-payment") {
            customPayment.total += theValue;
            customPayment.quantity += 1;
          }
        });

        this.contractInlineReport.total = theTotal;
        this.contractInlineReport.billingTickets.total = billingTicket.total;
        this.contractInlineReport.billingTickets.quantity =
          billingTicket.quantity;

        this.contractInlineReport.paymentCard.total = paymentCard.total;
        this.contractInlineReport.paymentCard.quantity = paymentCard.quantity;

        this.contractInlineReport.creditCard.total = creditCard.total;
        this.contractInlineReport.creditCard.quantity = creditCard.quantity;

        this.contractInlineReport.creditCard.PDQs.total = creditCard.PDQs.total;
        this.contractInlineReport.creditCard.PDQs.quantity =
          creditCard.PDQs.quantity;

        this.contractInlineReport.creditCard.payDirectWithTheSupplier.total =
          creditCard.payDirectWithTheSupplier.total;
        this.contractInlineReport.creditCard.payDirectWithTheSupplier.quantity =
          creditCard.payDirectWithTheSupplier.quantity;

        this.contractInlineReport.bankTransfer.total = bankTransfer.total;
        this.contractInlineReport.bankTransfer.quantity = bankTransfer.quantity;

        this.contractInlineReport.customPayment.total = customPayment.total;
        this.contractInlineReport.customPayment.quantity =
          customPayment.quantity;
      }
      return filteredContracts;
    },
    filterValues(contract) {
      contract;

      let flag = true;

      if (this.filterValue.min || this.filterValue.max) {
        let min = this.filterValue.min;
        let max = this.filterValue.max;
        let theValue = contract.payment_value
          ? parseFloat(contract.payment_value.replace(",", "."))
          : 0.0;

        console.log("filterValues", min, max);

        if (min) {
          if (!max && max == undefined) max = 500000.0;
        }

        if (max) {
          if (!min && min == undefined) min = 0.0;
        }

        console.log("filterValues", contract.id, theValue, min, max);

        if (theValue > parseInt(min) && theValue < parseInt(max)) {
          flag = true;
        } else {
          flag = false;
        }
      }

      return flag;
    },
    filterBankTransfers(contract) {
      let flag = true;

      if (
        this.contract.filters.paymentMethods.selected.includes("bank-transfer")
      ) {
        if (this.filterBankTransfer.length > 0) {
          let id = contract.meta[`payment_methods_bank_transfer_bank`];
          if (this.filterBankTransfer.includes(id)) {
            flag = true;
          } else {
            flag = false;
          }
        }
      }

      return flag;
    },
    filterBillingTickets(contract) {
      let flag = true;

      if (
        this.contract.filters.paymentMethods.selected.includes("billing-ticket")
      ) {
        if (this.filterBillingTicket.length > 0) {
          let id = contract.meta[`payment_methods_billing_ticket_tax_id`];
          if (this.filterBillingTicket.includes(id)) {
            flag = true;
          } else {
            flag = false;
          }
        }
      }

      return flag;
    },
    filterPDQs(contract) {
      let flag = true;

      if (
        this.contract.filters.paymentMethods.selected.includes("credit-card")
      ) {
        if (this.filterPdq.length > 0) {
          let cardMachineId =
            contract.meta[
              `payment_methods_credit_card_machine_id_${contract.payment_card_id}`
            ];
          if (this.filterPdq.includes(cardMachineId)) {
            flag = true;
          } else {
            flag = false;
          }
        }
      }

      return flag;
    },
    filterPaymentMethods(contract) {
      let flag = true;

      if (this.contract.filters.paymentMethods.selected.length > 0) {
        if (
          this.contract.filters.paymentMethods.selected.includes(
            contract.payment
          )
        ) {
          flag = true;
        } else {
          flag = false;
        }
      }

      return flag;
    },
    filterPaymentStatus(contract) {
      let flag = true;

      if (this.contract.filters.saleStatus.selected.length > 0) {
        if (
          this.contract.filters.saleStatus.selected.includes(
            contract.payment_status
          )
        ) {
          flag = true;
        } else {
          flag = false;
        }
      }

      return flag;
    },
    customerSelected(customer) {
      this.customers.filters.searchTerm = customer;
      this.contract.filters.customer = customer;

      this.getContracts();
    },
    viewSale(id) {
      this.openSaleModal = true;
      this.saleId = id;
    },
    paymentName(payment, contract) {
      let theName = "";

      if (payment == "credit-card") {
        let cardMachineId =
            contract.meta[
              `payment_methods_credit_card_machine_id_${contract.payment_card_id}`
            ],
          theCard = this.cardMachines.list.filter((cCard) => {
            return cCard.id == cardMachineId;
          });
        theName = theCard[0].name + "\n (c. credito)";
      }

      if (payment == "bank-transfer") {
        let bankId = contract.meta[`payment_methods_bank_transfer_bank`],
          thebank = this.banksList.filter((bank) => {
            return bank.account == bankId;
          });
        theName = thebank[0].company_name + "\n  (transf.)";
      }

      if (payment == "payment-card") {
        theName = "Carta de Pagamento";
      }

      if (payment == "custom-payment") {
        theName =
          contract.meta[`payment_methods_custom_payment_name`] +
          " (personalizado)";
      }

      if (payment == "billing-ticket") {
        let id = contract.meta[`payment_methods_billing_ticket_tax_id`],
          theBillingTicket = this.billingTickets.list.filter((bTicket) => {
            return bTicket.id == id;
          });
        theName = theBillingTicket[0].name;
      }

      return theName;
    },
    onCloseSaleModal() {
      this.openSaleModal = false;
      this.getContracts(this.contract.pagination.page);
    },
  },
};
</script>

<style lang="sass">
.field
  position: relative
  padding-top: 10px
  label
    font-size: 11px
    color: #999
  .label
    position: absolute
    font-size: 10px
    font-weight: 600
    color: #434a53 !important
    top: -10px
    z-index: 1
.travel-filters
  .field.payments
    .ant-checkbox-wrapper
      margin: 0 !important
      padding: 4px 11px 0px 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
.sale-outer-modal
  .ant-modal-close
    background: #cbcbcb!important
    border-radius: 0 0 0 5px
    .ant-modal-close-x
      width: 26px
      height: 18px
      font-size: 12px
      line-height: 19px
  .ant-modal-body
    padding: 0
</style>
